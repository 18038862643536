import "./AddShop.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { registerttShop } from "../redux/apiRequest";
import { useEffect } from "react";
import {
    apiGetPublicProvinces,
    apiGetPublicDistrict,
    apiGetPublicWard,
} from "../redux/ApiProvince";
import Loading from "../GiaoDienChung/Loading";
const AddShop = (props) => {
    const { setloading } = props;
    const user = useSelector((state) => state?.auth?.login?.currentUser);
    const TTLoading = useSelector((state) => state?.ttShop?.ttShop?.isFetching);
    const message = useSelector(
        (state) => state?.ttShop?.ttShop?.ttShop?.message
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [Banner, setBanner] = useState();
    const [TenShop, setTenShop] = useState();
    const [thonXom, setthonXom] = useState();
    const [SdtShop, setSdtShop] = useState();
    const [UserShop, setUserShop] = useState(user?._id);
    // Provinces
    const [provinces, setProvinces] = useState([]);
    const [provincesID, setprovincesID] = useState();
    const [districts, setDistricts] = useState([]);
    const [districtID, setDistrictID] = useState();

    const [wards, setWards] = useState([]);
    const [wardID, setWardID] = useState();
    //  Que Quan

    // Tinh
    useEffect(() => {
        const fetchPublicProvince = async () => {
            const response = await apiGetPublicProvinces();
            if (response?.status === 200) {
                setProvinces(response?.data.results);
            }
        };
        fetchPublicProvince();
    }, []);
    useEffect(() => {
        const fetchPublicDictrict = async () => {
            const response = await apiGetPublicDistrict(provincesID);
            if (response.status === 200) {
                setDistricts(response?.data.results);
            }
        };
        provincesID && fetchPublicDictrict();

        !provincesID && setDistricts([]);
    }, [provincesID]);
    useEffect(() => {
        const fetchPublicWard = async () => {
            const response = await apiGetPublicWard(districtID);
            if (response.status === 200) {
                setWards(response?.data.results);
            }
        };
        districtID && fetchPublicWard();

        !provincesID && setWards([]);
    }, [districtID]);
    // Que Quan
    const handleLuuThongTinShop = () => {
        if (!message || message !== "Tao moi thanh Cong") {
            // Que Quan
            const tenTinh = provinces?.find(
                (item) => item.province_id === provincesID
            );
            const tenHuyen = districts?.find(
                (item) => item.district_id === districtID
            );
            const tenXa = wards?.find((item) => item.ward_id === wardID);
            const menuShop = [
                "Khuyến Mại Đặc Biệt",
                "Điện Thoại",
                "Quần Áo",
                "Đồng Hồ ",
                "Hoa Quả",
            ];
            const nvBanHang = [{ sdt: SdtShop, ten: "Chủ Shop" }];
            const nvQuanLy = [{ sdt: SdtShop, ten: "Chủ Shop" }];
            const soBan = ["Bàn 1", "Bàn 2", "Bàn 3"];
            if (!TenShop || !SdtShop || !tenTinh || !tenHuyen) {
                alert("Vui lòng nhập đủ thông tin");
            } else {
                try {
                    const newShop = {
                        TenShop: TenShop,
                        sdtShop: SdtShop,

                        tinh: tenTinh?.province_name,
                        huyen: tenHuyen?.district_name,
                        xa: tenXa?.ward_name || "Trống",
                        thonXom: thonXom || "Trống",

                        cash: 300000,
                        phiNenTang: "1K/Đơn Hàng",
                        capBac: 1,
                        xuatBan: "Xây Dựng",
                        ttShopThem: {
                            menuShop,
                            giaoDien: "1",
                            nvBanHang,
                            nvQuanLy,
                            soBan,
                            tenVietTat: "ten-ngan",
                        },
                        allAnhShop: [],
                        user: UserShop || user._id,
                    };
                    console.log("newShop", newShop);
                    registerttShop(newShop, dispatch);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    };
    return (
        <div className="addShop-container">
            <div className="quayLai-tieuDe">
                <a href={`/ca-nhan/a/a/a/a/a/a`} className="quayLai">
                    <i className="fa fa-angle-double-left"></i>Quay Lại
                </a>
                <div className="tieuDe">Thêm Shop Mới</div>
            </div>
            <div className="addShop">
                <div className="tenShop-TS">
                    <div className="tenShop">Tên Shop</div>
                    <input
                        className="TS"
                        placeholder="Nhập Tên Shop"
                        type="text"
                        onChange={(e) => setTenShop(e.target.value)}
                    />
                </div>

                <div className="tenShop-TS">
                    <div className="tenShop">Số Điện Thoại</div>
                    <input
                        className="TS"
                        placeholder="Nhập Số Điện Thoại"
                        type="text"
                        onChange={(e) => setSdtShop(e.target.value)}
                    />
                </div>

                <div className="diaChi-container">
                    <div className="diaChi">Địa Chỉ</div>

                    <div className="tinh-huyen-xa">
                        <select
                            id="provinces"
                            onChange={(e) => setprovincesID(e.target.value)}
                        >
                            <option value="">Tỉnh/Thành Phố</option>
                            {provinces?.map((item) => {
                                return (
                                    <option
                                        key={item.province_id}
                                        value={item.province_id}
                                    >
                                        {item.province_name}
                                    </option>
                                );
                            })}
                        </select>
                        <select onChange={(e) => setDistrictID(e.target.value)}>
                            <option value="">Quận/Huyện</option>
                            {districts?.map((item) => {
                                return (
                                    <option
                                        value={item.district_id}
                                        key={item.district_id}
                                    >
                                        {item.district_name}
                                    </option>
                                );
                            })}
                        </select>
                        <select onChange={(e) => setWardID(e.target.value)}>
                            <option value=""> Xã/Phường</option>
                            {wards?.map((item) => {
                                return (
                                    <option
                                        value={item.ward_id}
                                        key={item.ward_id}
                                    >
                                        {item.ward_name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <input
                        className="soNha"
                        placeholder="Số nhà/Thôn/Xóm/..."
                        type="text"
                        onChange={(e) => setthonXom(e.target.value)}
                    />
                </div>
                <div className="luuY">
                    Shop mới được tặng 300.000&#160;<span>F&#160;</span>
                </div>
                {!message || message !== "Tao moi thanh Cong" ? (
                    <div className="luuShop" onClick={handleLuuThongTinShop}>
                        Thêm Shop Mới
                    </div>
                ) : (
                    <div className="daluuShop">Đã Thêm Mới</div>
                )}
            </div>
            {TTLoading === true && <Loading />}
        </div>
    );
};
export default AddShop;
