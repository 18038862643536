import "./UpdateSanPham.scss";
import { useState } from "react";
import { useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { resizeAnh } from "../component/UploadAnh";
import {
    getttShop,
    getIdSanPhamShop,
    updateSanPham,
    deleteSanPham,
} from "../redux/apiRequest";
import Loading from "../GiaoDienChung/Loading";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
const UpdateSanPham = () => {
    const { idSanPham, idShop, trangTruoc } = useParams();
    const ttShop = useSelector((state) => state?.ttShop?.ttShop?.ttShop?.shop);
    const sanPham = useSelector(
        (state) => state?.sanPham?.sanPham?.sanPham?.sanPham
    );
    console.log("sanPham", sanPham);
    const loadingSP = useSelector(
        (state) => state?.sanPham?.sanPham?.isFetching
    );
    const xoaSP = useSelector(
        (state) => state?.sanPham?.sanPham?.sanPham?.message
    );
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const allNhomSanPham = ttShop?.ttShopThem?.menuShop;

    useEffect(() => {
        getIdSanPhamShop(idSanPham, dispatch);
    }, [idSanPham]);
    useEffect(() => {
        getttShop(idShop, dispatch);
    }, [idShop]);

    const [tenSP, settenSP] = useState(sanPham?.TenSanPham || "");
    const [nhomSP, setnhomSP] = useState(sanPham?.nhomSanPham || "");
    const [allDacDiemSP, setallDacDiemSP] = useState(
        sanPham?.allDacDiemSP || []
    );
    const [allAnhSp, setallAnhSp] = useState([]);
    const [thongTinSP, setthongTinSP] = useState(
        sanPham?.thongTinSanPham || ""
    );
    useEffect(() => {
        settenSP(sanPham?.TenSanPham || "");
        setnhomSP(sanPham?.nhomSanPham || "");
        setallDacDiemSP(sanPham?.allDacDiemSP || []);
        setallAnhSp(sanPham?.allAnhSp || []);
        setthongTinSP(sanPham?.thongTinSanPham || "");
    }, [sanPham]);
    // Dac diem Phan Loai SP
    const [AnhSanPham, setAnhSanPham] = useState("");
    const [tenDacDiem, settenDacDiem] = useState("");
    const [giaVon, setgiaVon] = useState("");
    const [soLuong, setsoLuong] = useState(1);
    const [giaNiemYet, setgiaNiemYet] = useState(0);
    const [giaKhuyenMai, setgiaKhuyenMai] = useState(0);
    const [giaCtv, setgiaCtv] = useState(0);
    const [giaSi, setgiaSi] = useState(0);
    const [view, setview] = useState(sanPham?.view || "Hiện");

    const handleThemDacDiem = () => {
        if (
            !AnhSanPham ||
            !tenDacDiem ||
            !giaVon ||
            !soLuong ||
            !giaNiemYet ||
            !giaKhuyenMai ||
            !giaCtv ||
            !giaSi
        ) {
            alert("Hãy nhập đủ thông tin");
        } else {
            const dacDiemSanPham = {
                tenDacDiem,
                giaVon,
                giaNiemYet,
                soLuong,
                giaKhuyenMai,
                giaCtv,
                giaSi,
                slMua: 0,
            };
            const AnhSp = {
                tenDacDiem,
                AnhSanPham,
            };
            setallDacDiemSP([...allDacDiemSP, dacDiemSanPham]);
            setallAnhSp([...allAnhSp, AnhSp]);

            // reset input
            document.getElementById("input1").value = "";
            document.getElementById("input2").value = "";
            document.getElementById("input3").value = "";
            document.getElementById("input4").value = "";
            document.getElementById("input5").value = "";
            document.getElementById("input6").value = "";
            document.getElementById("input7").value = "";
            // reset input
            // reset data
            setAnhSanPham("");
            settenDacDiem("");
            setgiaVon(0);
            setgiaNiemYet(0);
            setsoLuong(1);
            setgiaKhuyenMai(0);
            setgiaCtv(0);
            setgiaSi(0);
            // reset data
        }
    };
    const handleXoaDacDiem = (tenDacDiem) => {
        if (allDacDiemSP?.length !== 0) {
            setallDacDiemSP(
                allDacDiemSP?.filter((item) => item?.tenDacDiem !== tenDacDiem)
            );
        }
        if (allAnhSp?.length !== 0) {
            setallAnhSp(
                allAnhSp?.filter((item2) => item2?.tenDacDiem !== tenDacDiem)
            );
        }
    };
    // Dac diem Phan Loai SP
    // Xu Ly Anh SP
    const handleSuaAnh = async (event, item) => {
        const anhSPBase64 = await resizeAnh(event);
        const dacDiemMoi = { AnhSanPham: anhSPBase64 };
        const ProductExist = allAnhSp?.find((item2) => item2 === item);
        if (ProductExist) {
            const ProductExist2 = { ...ProductExist, ...dacDiemMoi };
            const ProductExist3 = allAnhSp?.map((item3) =>
                item3 !== item ? item3 : ProductExist2
            );
            setallAnhSp(ProductExist3);
        }
    };
    const handleThemAnh = async (event) => {
        const anhResize = await resizeAnh(event);
        if (anhResize) {
            setAnhSanPham(anhResize);
        }
    };
    // Xu Ly Anh SP
    // sua dac Diem
    const suaDacDiem = (item, dacDiemMoi) => {
        if (allDacDiemSP?.length !== 0) {
            const ProductExist = allDacDiemSP?.find((item2) => item2 === item);
            if (ProductExist) {
                const ProductExist2 = { ...ProductExist, ...dacDiemMoi };
                const ProductExist3 = allDacDiemSP?.map((item3) =>
                    item3 !== item ? item3 : ProductExist2
                );
                console.log("ProductExist3", ProductExist3);
                setallDacDiemSP(ProductExist3);
            }
        }
    };
    const suaTenDacDiem = (tenDacDiem, dacDiemMoi) => {
        if (allDacDiemSP?.length !== 0) {
            const timDacDiem = allDacDiemSP?.find(
                (item) => item?.tenDacDiem === tenDacDiem
            );
            if (timDacDiem) {
                const dacDiem2 = { ...timDacDiem, ...dacDiemMoi };
                const dacDiem3 = allDacDiemSP?.map((item2) =>
                    item2.tenDacDiem !== tenDacDiem ? item2 : dacDiem2
                );
                setallDacDiemSP(dacDiem3);
            }
        }
        if (allAnhSp?.length !== 0) {
            const timAnh = allAnhSp?.find(
                (item3) => item3?.tenDacDiem === tenDacDiem
            );
            if (timAnh) {
                const anhSp2 = { ...timAnh, ...dacDiemMoi };
                const anhSp3 = allAnhSp?.map((item4) =>
                    item4.tenDacDiem !== tenDacDiem ? item4 : anhSp2
                );
                setallAnhSp(anhSp3);
            }
        }
    };
    // sua dac Diem
    // Luu Sp
    const handleLuuSanPham = () => {
        if (allDacDiemSP?.length < 1) {
            alert("Hãy nhập đủ thông tin");
        } else {
            const newSanPham = {
                nhomSanPham: nhomSP,
                TenSanPham: tenSP,
                giaMin: allDacDiemSP[0]?.giaKhuyenMai,
                allDacDiemSP: allDacDiemSP,
                allAnhSp: allAnhSp,
                thongTinSanPham: thongTinSP,
                TenShop: ttShop?.TenShop,
                view: view,
                xa: ttShop?.xa,
                huyen: ttShop?.huyen,
                tinh: ttShop?.tinh,
                capBac: ttShop?.capBac,
                idChuShop: ttShop?.user,
                idShop: idShop,
            };
            console.log("newSanPham", newSanPham);
            updateSanPham(newSanPham, sanPham?._id, dispatch);
        }
    };
    // Luu Sp
    // Xoa san pham
    const handleXoaSanPham = (id) => {
        const xoaSp = window.confirm("Xoá sản phẩm này?");
        if (xoaSp) {
            deleteSanPham(id, dispatch);
        }
    };
    useEffect(() => {
        if (xoaSP === "Delete thành công!") {
            navigate(`/sua-menu/${ttShop?._id}`);
        }
    });
    // Xoa san pham
    const handleQuayLai = () => {
        if (trangTruoc === "shop") {
            navigate(
                `/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/${ttShop?.ttShopThem?.giaoDien}/a/a/a`
            );
        } else {
            navigate(`/sua-menu/${ttShop?._id}`);
        }
    };
    return (
        <div className="UpdateSanPham-ConTaiNer">
            <div className="quayLai-tieuDe">
                <div onClick={() => handleQuayLai()} className="quayLai">
                    <i className="fa fa-angle-double-left"></i>Quay Lại
                </div>

                <div className="tieuDe">Sửa Sản Phẩm</div>
            </div>
            {sanPham && sanPham?._id === idSanPham && (
                <div className="AddSp">
                    <div className="nhomTen">
                        <select
                            className="nhomSanPham"
                            onChange={(e) => setnhomSP(e.target.value)}
                        >
                            <option>{nhomSP}</option>
                            {allNhomSanPham &&
                                allNhomSanPham?.length !== 0 &&
                                allNhomSanPham?.map((item, index) => {
                                    return <option key={index}>{item}</option>;
                                })}
                        </select>
                        <br />
                        <div className="tenSp-hienAn">
                            <input
                                id="input0"
                                className="tenSanPham"
                                type="text"
                                placeholder={sanPham?.TenSanPham}
                                onChange={(e) => settenSP(e.target.value)}
                            />
                            {view === "Hiện" && (
                                <div
                                    onClick={() => setview("Ẩn")}
                                    className="hien"
                                >
                                    <i className="fa fa-eye"></i>&ensp;Hiện
                                </div>
                            )}
                            {view === "Ẩn" && (
                                <div
                                    onClick={() => setview("Hiện")}
                                    className="an"
                                >
                                    <i className="fa fa-eye-slash"></i>&ensp;Ẩn
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="phanLoaiSanPham">
                        Đặc Điểm Phân Loại (Size, Màu sắc, Kích thước, ...)
                    </div>
                    {/* {allDacDiemSP &&
                        allDacDiemSP?.length > 0 &&
                        allDacDiemSP?.map((item, index) => {
                            return (
                                <div key={index} className="chiTietPhanLoai2">
                                    <div className="uploadAnh-container">
                                        <input
                                            id={index}
                                            type="file"
                                            name="upload_file"
                                            onChange={(event) =>
                                                handleSuaAnh(event, item)
                                            }
                                            hidden
                                        />
                                        <label
                                            htmlFor={index}
                                            className="uploadAnh"
                                        >
                                            <img
                                                src={item?.AnhSanPham}
                                                className="anhPreview"
                                            />
                                        </label>
                                    </div>
                                    <div className="phanLoai-container">
                                        <div className="tenDD-giaV-giaNY-giaKM">
                                            <div className="tenDD-themInput">
                                                <div className="tenDD">
                                                    Tên Đặc Điểm
                                                </div>
                                                <input
                                               
                                                    className="themInput"
                                                    type="text"
                                                    placeholder={
                                                        item?.tenDacDiem
                                                    }
                                                    onChange={(e) =>
                                                        suaDacDiem(item, {
                                                            tenDacDiem:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="tenDD-themInput">
                                                <div className="tenDD">
                                                    Giá Vốn
                                                </div>
                                                <input
                                              
                                                    className="themInput"
                                                    type="number"
                                                
                                                    
                                                    placeholder={VND.format(
                                                        item?.giaVon
                                                     )}
                                                    onChange={(e) =>
                                                        suaDacDiem(item, {
                                                            giaVon: e.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="tenDD-themInput">
                                                <div className="tenDD">
                                                    Giá Niêm Yết
                                                </div>
                                                <input
                                                  
                                                    className="themInput"
                                                    type="number"
                                                 
                                                    placeholder={VND.format(
                                                        item?.giaNiemYet
                                                     )}
                                                    onChange={(e) =>
                                                        suaDacDiem(item, {
                                                            giaNiemYet:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="tenDD-themInput">
                                                   <div className="tenDD">
                                                    Số Lượng
                                                </div>
                                                <input
                                                  
                                                    className="themInput"
                                                    type="number"
                                                    placeholder={item.soLuong}
                                                    onChange={(e) =>
                                                        suaDacDiem(item, {
                                                            soLuong:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="tenDD-giaV-giaNY-giaKM">
                                            <div className="tenDD-themInput">
                                                <div className="tenDD">
                                                    Giá Khuyến Mại
                                                </div>
                                                <input
                                           
                                                    className="themInput"
                                                    type="number"
                                                
                                                    placeholder={VND.format(
                                                        item?.giaKhuyenMai
                                                     )}
                                                    onChange={(e) =>
                                                        suaDacDiem(item, {
                                                            giaKhuyenMai:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="tenDD-themInput">
                                                <div className="tenDD">
                                                    Giá Khách VIP
                                                </div>
                                                <input
                                                
                                                    className="themInput"
                                                    type="number"
                                       
                                                    placeholder={VND.format(
                                                        item?.giaCtv
                                                     )}
                                                    onChange={(e) =>
                                                        suaDacDiem(item, {
                                                            giaCtv: e.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="tenDD-themInput">
                                                <div className="tenDD">
                                                    Giá Khách VIP+
                                                </div>
                                                <input
                                                
                                                    className="themInput"
                                                    type="number"
                                                    placeholder={VND.format(
                                                        item?.giaSi
                                                     )}
                                                
                                                    onChange={(e) =>
                                                        suaDacDiem(item, {
                                                            giaSi: e.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div
                                                onClick={() =>
                                                    handleXoaDacDiem(item)
                                                }
                                                className="xoa"
                                            >
                                                <i className="fa fa-remove"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })} */}
                    {allDacDiemSP &&
                        allDacDiemSP?.legth !== 0 &&
                        allDacDiemSP?.map((item, index) => {
                            return (
                                <div key={index}>
                                    {allAnhSp?.map((item2, index2) => {
                                        return (
                                            <div key={index2}>
                                                {index === index2 && (
                                                    <div className="chiTietPhanLoai2">
                                                        <div className="uploadAnh-container">
                                                            <input
                                                                id={
                                                                    item2?.tenDacDiem
                                                                }
                                                                type="file"
                                                                name="upload_file"
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    handleSuaAnh(
                                                                        event,
                                                                        item2
                                                                    )
                                                                }
                                                                hidden
                                                            />
                                                            <label
                                                                htmlFor={
                                                                    item2?.tenDacDiem
                                                                }
                                                                className="uploadAnh"
                                                            >
                                                                <img
                                                                    src={
                                                                        item2?.AnhSanPham
                                                                    }
                                                                    className="anhPreview"
                                                                />
                                                            </label>
                                                        </div>
                                                        <div className="phanLoai-container">
                                                            <div className="tenDD-giaV-giaNY-giaKM">
                                                                <div className="tenDD-themInput">
                                                                    <div className="tenDD">
                                                                        Tên Đặc
                                                                        Điểm
                                                                    </div>
                                                                    <input
                                                                        className="themInput"
                                                                        type="text"
                                                                        placeholder={
                                                                            item?.tenDacDiem
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            suaTenDacDiem(
                                                                                item?.tenDacDiem,
                                                                                {
                                                                                    tenDacDiem:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="tenDD-themInput">
                                                                    <div className="tenDD">
                                                                        Giá Vốn
                                                                    </div>
                                                                    <input
                                                                        className="themInput"
                                                                        type="number"
                                                                        placeholder={VND.format(
                                                                            item?.giaVon
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            suaDacDiem(
                                                                                item,
                                                                                {
                                                                                    giaVon: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="tenDD-themInput">
                                                                    <div className="tenDD">
                                                                        Giá Niêm
                                                                        Yết
                                                                    </div>
                                                                    <input
                                                                        className="themInput"
                                                                        type="number"
                                                                        placeholder={VND.format(
                                                                            item.giaNiemYet
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            suaDacDiem(
                                                                                item,
                                                                                {
                                                                                    giaNiemYet:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="tenDD-themInput">
                                                                    <div className="tenDD">
                                                                        Số Lượng
                                                                    </div>
                                                                    <input
                                                                        className="themInput"
                                                                        type="number"
                                                                        placeholder={
                                                                            item.soLuong
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            suaDacDiem(
                                                                                item,
                                                                                {
                                                                                    soLuong:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="tenDD-giaV-giaNY-giaKM">
                                                                <div className="tenDD-themInput">
                                                                    <div className="tenDD">
                                                                        Giá
                                                                        Khuyến
                                                                        Mại
                                                                    </div>
                                                                    <input
                                                                        className="themInput"
                                                                        type="number"
                                                                        placeholder={VND.format(
                                                                            item.giaKhuyenMai
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            suaDacDiem(
                                                                                item,
                                                                                {
                                                                                    giaKhuyenMai:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="tenDD-themInput">
                                                                    <div className="tenDD">
                                                                        Giá
                                                                        Khách
                                                                        VIP
                                                                    </div>
                                                                    <input
                                                                        className="themInput"
                                                                        type="number"
                                                                        placeholder={VND.format(
                                                                            item.giaCtv
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            suaDacDiem(
                                                                                item,
                                                                                {
                                                                                    giaCtv: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="tenDD-themInput">
                                                                    <div className="tenDD">
                                                                        Giá
                                                                        Khách
                                                                        VIP+
                                                                    </div>
                                                                    <input
                                                                        className="themInput"
                                                                        type="number"
                                                                        placeholder={VND.format(
                                                                            item.giaSi
                                                                        )}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            suaDacDiem(
                                                                                item,
                                                                                {
                                                                                    giaSi: e
                                                                                        .target
                                                                                        .value,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    onClick={() =>
                                                                        handleXoaDacDiem(
                                                                            item.tenDacDiem
                                                                        )
                                                                    }
                                                                    className="xoa"
                                                                >
                                                                    <i className="fa fa-remove"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    <div className="chiTietPhanLoai2">
                        <div className="uploadAnh-container">
                            <input
                                id="idAnh"
                                type="file"
                                name="upload_file"
                                onChange={(event) => handleThemAnh(event)}
                                hidden
                            />
                            <label htmlFor="idAnh" className="uploadAnh">
                                {AnhSanPham ? (
                                    <img
                                        src={AnhSanPham}
                                        className="anhPreview"
                                    />
                                ) : (
                                    <div className="themAnh">
                                        <i className="fa fa-image"></i>
                                    </div>
                                )}
                            </label>
                        </div>
                        <div className="phanLoai-container">
                            <div className="tenDD-giaV-giaNY-giaKM">
                                <div className="tenDD-themInput">
                                    <div className="tenDD">Tên Đặc Điểm</div>
                                    <input
                                        id="input1"
                                        className="themInput"
                                        type="text"
                                        placeholder='. . . . . . . . . . .'
                                        onChange={(e) =>
                                            settenDacDiem(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="tenDD-themInput">
                                    <div className="tenDD">Giá Vốn</div>
                                    <input
                                        id="input2"
                                        className="themInput"
                                        type="number"
                                        placeholder={VND.format(0)}
                                        onChange={(e) =>
                                            setgiaVon(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="tenDD-themInput">
                                    <div className="tenDD">Giá Niêm Yết</div>
                                    <input
                                        id="input3"
                                        className="themInput"
                                        type="number"
                                        placeholder={VND.format(0)}
                                        onChange={(e) =>
                                            setgiaNiemYet(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="tenDD-themInput">
                                    <div className="tenDD">Số Lượng</div>
                                    <input
                                        id="input5"
                                        className="themInput"
                                        type="number"
                                        placeholder={soLuong}
                                        onChange={(e) =>
                                            setsoLuong(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="tenDD-giaV-giaNY-giaKM">
                                <div className="tenDD-themInput">
                                    <div className="tenDD">Giá Khuyến Mại</div>
                                    <input
                                        id="input4"
                                        className="themInput"
                                        type="number"
                                        placeholder={VND.format(0)}
                                        onChange={(e) =>
                                            setgiaKhuyenMai(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="tenDD-themInput">
                                    <div className="tenDD">Giá Khách VIP</div>
                                    <input
                                        id="input6"
                                        className="themInput"
                                        type="number"
                                        placeholder={VND.format(0)}
                                        onChange={(e) =>
                                            setgiaCtv(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="tenDD-themInput">
                                    <div className="tenDD">Giá Khách VIP+</div>
                                    <input
                                        id="input7"
                                        className="themInput"
                                        type="number"
                                        placeholder={VND.format(0)}
                                        onChange={(e) =>
                                            setgiaSi(e.target.value)
                                        }
                                    />
                                </div>
                                <div
                                    onClick={() => handleThemDacDiem()}
                                    className="them"
                                >
                                    <i className="fa fa-check"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="thongTinSanPham">
                        <div className="tieuDe">Thông Tin Sản Phẩm</div>
                        <div className="noiDung">
                            <CKEditor
                                editor={ClassicEditor}
                                data={sanPham?.thongTinSanPham || ""}
                                onReady={(editor) => {}}
                                onChange={(event, editor) => {
                                    console.log(event);
                                    setthongTinSP(editor?.getData());
                                }}
                                onBlur={(event, editor) => {}}
                                onFocus={(event, editor) => {}}
                            />
                        </div>
                    </div>
                    <div className="luuY">
                        Lưu ý: Giá Khách VIP và Giá Khách VIP+ tương đương cách
                        gọi Giá Cộng Tác Viên và Giá Sỉ
                    </div>
                    <div className="closeLuu">
                        <div
                            className="close"
                            onClick={() => handleXoaSanPham(sanPham._id)}
                        >
                            Xoá Sản Phẩm
                        </div>
                        <div onClick={handleLuuSanPham} className="luu">
                            Lưu Sản Phẩm
                        </div>
                    </div>
                </div>
            )}
            {loadingSP === true && <Loading />}
        </div>
    );
};
export default UpdateSanPham;
