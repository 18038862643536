import "./ThuNghiem.scss";
import React, { useRef, useState } from "react";

const ThuNghiem = () => {
    const chieuRongMH = window.innerWidth;
    const cartDemo = [
        {
            _id: "6656565",
            tenSp: "tenSp1",
            allDacDiem: [
                {
                    tenDacDiem: "tendd1",
                    sl: "0",
                },
                {
                    tenDacDiem: "tendd2",
                    sl: "2",
                },
                {
                    tenDacDiem: "tendd3",
                    sl: "3",
                },
            ],
        },
        {
            _id: "6656445445565",
            tenSp: "tenSp2",
            allDacDiem: [
                {
                    tenDacDiem: "tendd1",
                    sl: "4",
                },
                {
                    tenDacDiem: "tendd2",
                    sl: "0",
                },
                {
                    tenDacDiem: "tendd3",
                    sl: "0",
                },
            ],
        },
        {
            _id: "6656565",
            tenSp: "tenSp3",
            allDacDiem: [
                {
                    tenDacDiem: "tendd1",
                    sl: "0",
                },
                {
                    tenDacDiem: "tendd2",
                    sl: "0",
                },
                {
                    tenDacDiem: "tendd3",
                    sl: "0",
                },
            ],
        },
    ];
    console.log("cartDemo", cartDemo);
    console.log("cartDemo?.allDacDiem?.sl", cartDemo[0]?.allDacDiem[0]?.sl > 0);

    // const cart2 = cartDemo?.map(
    //     (item2) =>
    //         item2 &&
    //         item2?.allDacDiem?.find((item) => item?.sl > 0) && {
    //             _id: "6656565",
    //             tenSp: "tenSp3",
    //             allDacDiem: item2?.allDacDiem?.filter((item) => item?.sl > 0),
    //         }
    // );
    // const cart = cart2.filter((item) => item);

    const [donHangDaDat, setdonHangDaDat] = useState([]);
    const cart = cartDemo
        ?.map((item2) => {
            if (item2?.allDacDiem?.find((item) => item?.sl > 0)) {
                return {
                    _id: item2?._id,
                    tenSp: item2?.tenSp,
                    allDacDiem: item2?.allDacDiem?.filter(
                        (item) => item?.sl > 0
                    ),
                };
            } else {
                return;
            }
        })
        .filter((item3) => item3);

    // console.log("cart2", cart2);
    console.log("cart", cart);

    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="ThuNghiem-ConTaiNer">
                        <div>Thử Nghiệm</div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="ThuNghiem-ConTaiNer">pc</div>
                </div>
            )}
        </div>
    );
};
export default ThuNghiem;
