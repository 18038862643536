import "./HeaderShop.scss";
import avatarTrong from "../assets/images/logo2.jpeg";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getttShop, updatePost } from "../redux/apiRequest";

const HeaderShop = (props) => {
    const {
        giaoDien,
        Tongtien,
        Tongsoluong,
        loading,
        setloading,
        handleXemAnhFull,
        setTTMenu,
        handleSapXep,
        sort,
    } = props;
    const chieuRongMH = window.innerWidth;
    const { tenVietTat, idShop, idCtv, tenCtv, sdtCtv } = useParams();
    const dispatch = useDispatch();
    const ttShop = useSelector((state) => state.ttShop?.ttShop?.ttShop?.shop);
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    useEffect(() => {
        getttShop(idShop, dispatch);
    }, []);

    // like Shop
    const allLikeShop = myDetail?.likeShop;
    const handeleLikeShop = (idShop) => {
        const likeShop = [
            ...myDetail?.likeShop,
            {
                idShop: idShop,
                tenVietTat: ttShop?.ttShopThem?.tenVietTat,
                tenShop: ttShop?.TenShop,
                xa: ttShop?.xa,
                huyen: ttShop?.huyen,
                tinh: ttShop?.tinh,
                sdtShop: ttShop?.sdtShop,
            },
        ];

        const newPost = {
            likeShop: likeShop,
        };
        updatePost(newPost, myDetail._id, dispatch);
    };
    const handeleDislikeShop = (idShop) => {
        const likeShop = allLikeShop?.filter((item) => item?.idShop !== idShop);
        const newPost = {
            likeShop: likeShop,
        };
        updatePost(newPost, myDetail._id, dispatch);
    };
    const likeShop = allLikeShop?.find((item) => item?.idShop === idShop);
    // like Shop

    // slide Anh Shop
    const allAnhShop = ttShop?.allAnhShop;
    const [banner, setbanner] = useState();
    const [indexAnh, setindexAnh] = useState(0);
    // useEffect(() => {
    //     const bannerId = setInterval(() => {
    //         setindexAnh((prev) => (prev + 1) % allAnhShop?.length);
    //     }, 3000);
    //     return () => clearInterval(bannerId);
    // }, [ttShop]);

    useEffect(() => {
        if (ttShop?.allAnhShop.length > 0) {
            setbanner(allAnhShop[indexAnh]);
        }
    }, [ttShop, indexAnh]);

    const handleAnhTruoc = () => {
        if (indexAnh === 0) {
            setindexAnh(allAnhShop.length - 1);
        } else {
            setindexAnh(indexAnh - 1);
        }
    };

    // slide Anh Shop
    const handleDinhDangSo = (data) => {
        const n = +data;
        return n.toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
        });
    };
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="HeaderShop-ConTaiNer">
                        <div className="headerShop">
                            <div className="tenShop">{ttShop?.TenShop}</div>
                            {myDetail ? (
                                <a
                                    href={`/ca-nhan/${tenVietTat}/${idShop}/${giaoDien}/${idCtv}/${tenCtv}/${sdtCtv}`}
                                    className="caNhan"
                                >
                                    <div className="xinChao-taiKhoan">
                                        <div className="xinChao">Xin chào!</div>
                                        <div className="taiKhoan">
                                            {handleDinhDangSo(myDetail?.cash)}
                                            &#160;<span>F&#160;</span>
                                        </div>
                                    </div>

                                    <img
                                        src={myDetail?.avatar}
                                        alt="he"
                                        className="avatar"
                                    />
                                </a>
                            ) : (
                                <a
                                    href={`/ca-nhan/${tenVietTat}/${idShop}/${giaoDien}/${idCtv}/${tenCtv}/${sdtCtv}`}
                                    className="caNhan"
                                >
                                    <div className="xinChao-taiKhoan">
                                        <div className="xinChao">Xin chào!</div>
                                        <div className="taiKhoan">
                                            {handleDinhDangSo(99000)}
                                            &#160;<span>F&#160;</span>
                                        </div>
                                    </div>
                                    <img
                                        src={avatarTrong}
                                        alt="he"
                                        className="avatar"
                                    />
                                </a>
                            )}
                        </div>
                        <div className="banner-tenShop">
                            <div className="banner-tien-lui">
                                {banner ? (
                                    <img
                                        onClick={() => handleXemAnhFull(banner)}
                                        src={banner}
                                        className="banner"
                                    />
                                ) : (
                                    <div className="themAnh">
                                        <i className="fa fa-image"></i>
                                    </div>
                                )}

                                {allAnhShop?.length > 1 && (
                                    <div
                                        onClick={() => handleAnhTruoc()}
                                        className="lui"
                                    >
                                        <i className="fas fa-arrow-alt-circle-left"></i>
                                    </div>
                                )}

                                {allAnhShop?.length > 1 && (
                                    <div
                                        onClick={() =>
                                            setindexAnh(
                                                (prev) =>
                                                    (prev + 1) %
                                                    allAnhShop?.length
                                            )
                                        }
                                        className="tien"
                                    >
                                        <i className="fas fa-arrow-alt-circle-right"></i>
                                    </div>
                                )}
                                <div className="dauCham2">
                                    {allAnhShop &&
                                        allAnhShop?.length === 2 &&
                                        allAnhShop?.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        item === banner
                                                            ? "chamTo"
                                                            : "chamNho"
                                                    }
                                                >
                                                    <i className="fa fa-circle"></i>
                                                </div>
                                            );
                                        })}
                                </div>
                                <div className="dauCham3">
                                    {allAnhShop &&
                                        allAnhShop?.length === 3 &&
                                        allAnhShop?.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        item === banner
                                                            ? "chamTo"
                                                            : "chamNho"
                                                    }
                                                >
                                                    <i className="fa fa-circle"></i>
                                                </div>
                                            );
                                        })}
                                </div>
                                <div className="dauCham4">
                                    {allAnhShop &&
                                        allAnhShop?.length === 4 &&
                                        allAnhShop?.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        item === banner
                                                            ? "chamTo"
                                                            : "chamNho"
                                                    }
                                                >
                                                    <i className="fa fa-circle"></i>
                                                </div>
                                            );
                                        })}
                                </div>
                                <div className="dauCham5">
                                    {allAnhShop &&
                                        allAnhShop?.length === 5 &&
                                        allAnhShop?.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        item === banner
                                                            ? "chamTo"
                                                            : "chamNho"
                                                    }
                                                >
                                                    <i className="fa fa-circle"></i>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                            <div className="phimChucNang">
                                <div className="phim1-phim2">
                                    {!myDetail ? (
                                        <div
                                            onClick={() =>
                                                alert("Đăng nhập để sử dụng!")
                                            }
                                            className="icon-follow"
                                        >
                                            <div className="icon">
                                                <i
                                                    className="fa fa-check-circle"
                                                    style={{
                                                        color: "gray",
                                                    }}
                                                ></i>
                                            </div>

                                            <div className="follow">
                                                Follow shop
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {likeShop &&
                                            likeShop.length !== 0 ? (
                                                <div
                                                    onClick={(e) =>
                                                        handeleDislikeShop(
                                                            idShop
                                                        )
                                                    }
                                                    className="icon-follow"
                                                >
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-check-circle"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        ></i>
                                                    </div>

                                                    <div className="follow">
                                                        Follow shop
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={(e) =>
                                                        handeleLikeShop(idShop)
                                                    }
                                                    className="icon-follow"
                                                >
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-check-circle"
                                                            style={{
                                                                color: "gray",
                                                            }}
                                                        ></i>
                                                    </div>
                                                    <div className="follow">
                                                        Follow shop
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div
                                        onClick={() => setloading(8)}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="far fa-address-card"></i>
                                        </div>
                                        <div className="follow">Giới Thiệu</div>
                                    </div>
                                </div>
                                <div className="phim1-phim2">
                                    <div
                                        onClick={() => setloading(7)}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="fa fa-bank"></i>
                                        </div>
                                        <div className="follow">Ngân Hàng</div>
                                    </div>
                                    <div
                                        onClick={() => setTTMenu("hien")}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="fa fa-bars"></i>
                                        </div>
                                        <div className="follow">Menu Shop</div>
                                    </div>
                                </div>
                                <div className="phim1-phim2">
                                    <a
                                        href={`/mini-game/a/a/a/a/a/a`}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="fas fa-puzzle-piece"></i>
                                        </div>
                                        <div className="follow">Mini Game</div>
                                    </a>

                                    <div className="icon-follow">
                                        <div className="icon">
                                            <i className="fas fa-users"></i>
                                        </div>
                                        <div className="follow">
                                            Bạn Quanh Đây
                                        </div>
                                    </div>
                                </div>
                                <div className="phim1-phim2">
                                    {sort === 1 ? (
                                        <div
                                            className="icon-follow"
                                            onClick={() => handleSapXep()}
                                        >
                                            <div className="icon">
                                                <i className="fa fa-sort-numeric-desc"></i>
                                            </div>
                                            <div className="follow">
                                                Giá Giảm
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="icon-follow"
                                            onClick={() => handleSapXep()}
                                        >
                                            <div className="icon">
                                                <i className="fa fa-sort-numeric-asc"></i>
                                            </div>
                                            <div className="follow">
                                                Giá Tăng
                                            </div>
                                        </div>
                                    )}
                                    <a
                                        href={`/${
                                            ttShop?.ttShopThem?.tenVietTat
                                        }/${ttShop?._id}/${
                                            +giaoDien * -1
                                        }/a/a/a`}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="fas fa-retweet"></i>
                                        </div>
                                        {+giaoDien === 1 ? (
                                            <div className="follow">
                                                Giao Diện Blog
                                            </div>
                                        ) : (
                                            <div className="follow">
                                                Giao Diện Shop
                                            </div>
                                        )}
                                    </a>
                                </div>
                                <div className="phim1-phim2">
                                    <div className="icon-follow">
                                        <div className="icon">
                                            <i className="fas fa-people-carry"></i>
                                        </div>
                                        <div className="follow">
                                            Nhận Giao Hàng
                                        </div>
                                    </div>
                                    <div className="icon-follow">
                                        <div className="icon">
                                            <i className="fas fa-archive"></i>
                                        </div>
                                        <div className="follow">
                                            Tìm Giao Hàng
                                        </div>
                                    </div>
                                </div>

                                <div className="phim1-phim2">
                                    <a
                                        href={`/quan-ly-shop/${idShop}`}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="fas fa-sitemap"></i>
                                        </div>
                                        <div className="follow"> Quản Lý</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="HeaderShop-ConTaiNer">ViDu</div>
                </div>
            )}
        </div>
    );
};
export default HeaderShop;
